class Shim {
  private iframe = document.createElement('iframe');
  private modal = document.createElement("div");
  private shopToken = '';
  private customBgColor = '';
  private formOpened = false;
  private dockLeft = false;
  private bottomOffset = 0;
  private locations = null;
  private hideButton = false;
  private isCTAWidget = false;
  private welcomeMessage = '';
  private themeStartColor = '#6e2ebe';
  private themeEndColor = '#2e6ad9';
  private textColor = "#FFFFFF";
  private greeterImageUrl = null;
  private googleAnalyticsEnabled = true;
  private googleReviewsEnabled = true;
  private googleRating = null;
  private showGreeterMessage = false;
  private hideBranding = false;

  // New method to fetch the configuration before initializing the iframe
  fetchConfigAndInit = (defaultValues: any) => {
    let computedToken = defaultValues.token;
    if (defaultValues.locations?.length === 1) {
      const location = defaultValues.locations[0];
      computedToken = location.shopId || location.shop_id || location.id;
    }

    if (!computedToken) {
      console.log(`iK: debug: no token will not init`);
      return;
    }

    // Store shopToken to use later
    this.shopToken = defaultValues.token;
    fetch(`${process.env.WIDGET_API_HOST}/config?location_id=${computedToken}`)
      .then((response) => response.json())
      .then((config) => {
        if (!config.user_changed_settings) {
          // Override server config with 
          config.theme_start_color = defaultValues.customColor
          config.theme_end_color = defaultValues.customColor
          config.open_on_load = defaultValues.formOpened
          config.button_placement = defaultValues.dockLeft ? "bottom_left_corner" : "bottom_right_corner"
          config.bottom_offset = defaultValues.bottomOffset
          if (defaultValues.locations?.length) {
            config.locations = defaultValues.locations?.map(({shop_id, shopId, name}: any) => {
              return {
                id: shop_id || shopId, 
                name
              }
            })
          }
        }

        this.showGreeterMessage = config.show_welcome_cta;
        this.greeterImageUrl = config.greeter_image_url;
        this.textColor = config.text_color || "#FFFFFF";
        this.themeStartColor = config.theme_start_color || '#6e2ebe'; // Default color
        this.themeEndColor = config.theme_end_color || '#2e6ad9';
        this.formOpened = config.open_on_load;
        this.dockLeft = config.button_placement === 'bottom_left_corner';
        this.bottomOffset = config.bottom_offset || 0;
        this.locations = config.locations || [];
        // This is currently not returned by the config so use the defaultValues
        this.hideButton = defaultValues.hideButton;
        this.hideBranding = config.hide_branding;
        this.isCTAWidget = config.button_style === 'rounded' ? false : true;
        this.welcomeMessage = config.welcome_cta_text
          ? config.welcome_cta_text
          : '';
        this.googleReviewsEnabled = config.google_reviews_enabled;
        this.googleAnalyticsEnabled = config.google_analytics_enabled;
        this.googleRating = config.google_rating

        this.mountIframe();
      })
      .catch((error) => {
        console.error('Error fetching config:', error);
        // Optionally handle error (e.g., use default values or show an error message)
        this.mountIframe(); // Proceed with default values in case of error
      });
  };

  receiveMessage = (event: MessageEvent) => {
    const wrapper = document.getElementById('ikeono-wrapper');

    if (!!event && !!event.data && !!event.data.type) {
      switch (event.data.type) {
        case 'SEND_CONFIG':
          this.postConfigToIFrame()
          break;
        case 'MINIMIZE_WIDGET':
          if (window.screen.width < 576) {
            this.modal.style.display = event.data.value ? 'none' : 'block'
            this.modal.style.height = `${document.body.scrollHeight}px`;
          }
          const body = document.getElementsByTagName("body")[0];
          body.style.overflow = event.data.value ? "auto" : "hidden";
          this.toggleWidgetSize(event.data.value);
          break;
        case 'OPEN_WIDGET':
          this.toggleWidgetSize(false);
          this.iframe.contentWindow!.postMessage(
            {
              type: 'OPEN_WIDGET',
              message_body: event.data.message_body,
              location_id: event.data.location_id,
              mock_api_call: event.data.mock_api_call,
            },
            '*'
          );
          break;
        case 'CLOSE_WIDGET':
          this.iframe.contentWindow!.postMessage(
            {
              type: 'CLOSE_WIDGET',
            },
            '*'
          );
          break;
        case 'CURRENT_ORIGIN':
          this.iframe.contentWindow!.postMessage(
            {
              type: 'CURRENT_ORIGIN',
              origin: document.location.href,
              windowWidth: window.innerWidth
            },
            '*'
          );
          break;
        case 'USER_CLOSED_GREETER_MESSAGE':
          sessionStorage.setItem("userClosedGreeterImage", true);
          this.toggleWidgetSize(true);
          break;
      }
    }
  };

  toggleWidgetSize = (minimized: boolean) => {
    const wrapper = document.getElementById('ikeono-wrapper');
    if (!wrapper) return

    const [height, width] = this.getWrapperDimensions(minimized);


    if (minimized) {
      wrapper.style.height = height;
      wrapper.style.width = width;
      wrapper.style.transitionDelay = '.2s';
      wrapper.style.transitionProperty = 'width, height';
    } else {
      wrapper.style.height = `${window.innerHeight}px`;
      wrapper.style.width = '100vw';
      wrapper.style.transitionDelay = '0s';
    }

  };

  postConfigToIFrame = () => {
    this.iframe.contentWindow!.postMessage(
      {
        type: 'INIT_IFRAME',
        shopToken: this.shopToken,
        customBgColor: this.customBgColor,
        formOpened: this.formOpened,
        dockLeft: this.dockLeft,
        bottomOffset: this.bottomOffset,
        locations: this.locations,
        hideButton: this.hideButton,
        isCTAWidget: this.isCTAWidget,
        welcomeMessage: this.welcomeMessage,
        themeStartColor: this.themeStartColor,
        themeEndColor: this.themeEndColor,
        textColor: this.textColor,
        greeterImageUrl: this.greeterImageUrl,
        googleAnalyticsEnabled: this.googleAnalyticsEnabled,
        googleReviewsEnabled: this.googleReviewsEnabled,
        googleRating: this.googleRating,
        source:"shim",
        showGreeterMessage: this.showGreeterMessage,
        hideBranding: this.hideBranding
      },
      '*'
    );
  }

  getWrapperDimensions = () => {
    let height = this.isCTAWidget ? '79px' : '90px';
    let width = this.isCTAWidget ? '153px' : '90px';

    const userClosedGreeterImage = sessionStorage.getItem('userClosedGreeterImage');
    if (this.showGreeterMessage && !userClosedGreeterImage) {
      height = "175px";
      width = "295px";
    }

    if (this.hideButton) {
      height = '1px';
      width = '1px';
    }

    return [height, width];
  }


  mountIframe = () => {
    if (this.formOpened) {
      const alreadyOpened = sessionStorage.getItem('autoOpened');
      if (!alreadyOpened) {
        sessionStorage.setItem('autoOpened', true);
      } else {
        this.formOpened = false;
      }
    }
    
    // Update iframe
    this.iframe.src = process.env.SHIM_HOST;
    this.iframe.style.border = '0';
    this.iframe.style.width = '100%';
    this.iframe.style.height = '100%';
    this.iframe.setAttribute('title', 'Webchat Widget');
    this.iframe.setAttribute('id', 'ikeono_webchat_widget');

    this.modal.setAttribute("id", "ikeono-modal");
    this.modal.style.position = "absolute";
    this.modal.style.top = "0";
    this.modal.style.bottom = "0";
    this.modal.style.height = "calc(100vh + 150px)";
    this.modal.style.width = "100vw";
    this.modal.style.backgroundColor = "rgb(0,0,0,.6)";
    this.modal.style.overflow = 'hidden';
    this.modal.style.display = 'none';
    this.modal.style.pointerEvents = 'none';
    this.modal.style.zIndex = "999";
    document.body.appendChild(this.modal);


    // Update wrapper
    const wrapper = document.createElement('div');
    wrapper.setAttribute('id', 'ikeono-wrapper');
    wrapper.appendChild(this.iframe);
    wrapper.style.position = 'fixed';
    wrapper.style.bottom = this.bottomOffset ?  `${this.bottomOffset.toString()}px` : '0'
    wrapper.style.zIndex = '2147483647';

    const [height, width] = this.getWrapperDimensions();
    wrapper.style.height = height;
    wrapper.style.width = width;

    if (this.dockLeft) {
      wrapper.style.left = '0';
    } else {
      wrapper.style.right = '0';
    }

    if (document?.body) {
      document.body.appendChild(wrapper);
      window.addEventListener('message', this.receiveMessage, false);
    } else {
      console.warn('No body, sleeping');
      setTimeout(() => {
        document.body.appendChild(wrapper);
        window.addEventListener('message', this.receiveMessage, false);
      }, 250);
    }
  };
}

export default ((window) => {
  if (document.querySelectorAll('[name="ikeono-widget-ignore"]')[0]) {
    console.log(`ik: Will not run widget on page due to ikeono-widget-ignore`)
    return
  }
  const stubSdk = window.ikeono;
  const initCall = stubSdk._beforeLoadCallQueue.find(
    (call: string[]) => call[0] === 'init'
  );
  const token = initCall[1][0];
  const customColor = initCall[1][1];
  const formOpened = initCall[1][2];
  const dockLeft = initCall[1][3];
  const locations = initCall[1][5];
  const hideButton = initCall[1][6]

  let bottomOffset = 0;
  if (initCall[1].length >= 5) {
    bottomOffset = initCall[1][4];
  }

  const defaultValues = {
    token,
    customColor,
    formOpened,
    dockLeft,
    locations,
    hideButton,
    bottomOffset
  }

  const shim = new Shim();
  stubSdk.init = shim.fetchConfigAndInit.bind(shim); // Fetch settings and initialize

  if (initCall) {
    shim.fetchConfigAndInit(defaultValues);
  }
})(global);
